@import "parts/bootstrap-theme.css";
@import "parts/bootstrap.css";
// @import "parts/slick.css"
// @import "parts/animate.css"

// Custom styles

// Colors
$grey: #f7f7f7;
$accent: #588349;
$grey-text: #505050;

a:hover {
    color: inherit;
    text-decoration: none; }
a:link {
    text-decoration: none; }

*:focus {
    outline: none !important;
    text-decoration: none; }

body {
    font-family: 'Open Sans', sans-serif; }

.disable-x {
    display: none;
    animation: fadeOut 1.5s; }

.active-x {
    display: block;
    animation: fadeIn 1.5s; }

.btn-x {
    display: block;
    border: none;
    cursor: pointer;
    background-color: $accent;
    color: #ffffff;
    width: 240px;
    padding: 16px 0;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    transition: 0.5s;
    border-radius: 0;
    &:hover {
        color: #ffffff;
        background-color: darken($accent, 4%); }
    &_100 {
        width: 100%; }
    &-default {
        border: 2px solid $accent;
        background: transparent;
        color: #000000;
        padding: 14px 0;
        &:hover {
            color: #000000;
            background-color: transparent;
            box-shadow: none;
            border: 2px solid $accent; } }
    &-center {
        margin: auto; } }


.content {
    p {
        font-size: 16px;
        line-height: 28px;
        color: #262626;
        margin: 0 0 20px; }
    & {
        ul {
            color: #262626;
            margin-bottom: 25px;
            li {
                line-height: 1.5;
                font-size: 16px;
                margin-bottom: 8px; } }
        h4 {
            color: #000000; } } }

.top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 35px;
    background-color: $grey; }

.social-links {
    &__link {
        font-size: 14px;
        color: #3b3b3b;
        margin-right: 25px;
        transition: 0.5s;
        &_mobile {
            margin: 0;
            cursor: pointer; }
        &:hover {
            color: #000000; } } }

.user-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    &__item {
        margin-right: 30px;
        &:last-child {
            margin-right: 0; } }
    &__link {
        color: #3b3b3b;
        font-size: 12px;
        transition: 0.5s;
        &:hover {
            color: #000000; }
        &:link {
            color: #000000; }
        i {
            margin-right: 8px; } } }

.top-sidebar {
    padding: 50px 0;
    .row {
        display: flex;
        align-items: center; }
    &__info-block {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    &__link {
        color: #000000;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 3px;
        display: block;
        text-align: right;
        &:hover {
            color: #000000; }
        &:visited {
            color: #000000; } }

    &__text {
        padding: 0;
        margin: 0;
        font-size: 13px;
        color: #6e6e6e; }
    &__icon {
        color: $accent;
        font-size: 20px;
        margin-left: 20px; } }

.logo {
    position: relative;
    &_inline {
        display: inline-block;
        line-height: 2; }
    &__round {
        position: absolute;
        z-index: 10;
        height: 100px;
        width: 100px;
        border: 15px solid #ecf5fd;
        border-radius: 50%;
        top: -20px;
        left: 0;
        transition: .5s; }
    &__link {
        img {
            position: relative;
            z-index: 20; } }
    &__link:hover > .logo__round {
        transform: scale(1.15, 1.15);
        border: 15px solid #ffdfd0; } }

.search {
    position: relative;
    display: flex;
    align-items: center;
    .input-group {
        width: 100%; }
    input {
        width: 100%;
        background-color: $grey;
        border: none;
        padding: 12px 0 12px 17px;
        font-size: 15px;
        box-shadow: none;
        &:hover {
            box-shadow: none; }
        &:focus {
            box-shadow: none; } }
    button {
        padding: 0;
        position: absolute !important;
        border: none;
        background: transparent;
        top: 52%;
        margin-top: -11px;
        right: 17px;
        font-size: 16px;
        color: #b1b1b1;
        box-shadow: none;
        &:hover {
            box-shadow: none;
            background: transparent; } } }

.menu {
    background-color: $accent;
    .row {
        display: flex;
        align-items: center; }
    &__list {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none; }
    &__link {
        display: block;
        font-size: 14px;
        color: #ffffff;
        padding: 12px 30px;
        transition: 0.5s;
        font-size: 14px;
        &:visited {
            color: #ffffff; }
        &:hover {
            background-color: darken($accent, 3%);
            color: #ffffff; } }
    &__category {
        padding: 12px 40px;
        background-color: darken($accent, 5%);
        &:hover {
            background-color: darken($accent, 7%); } } }

.cart {
    height: 44px;
    display: flex;
    &_mobile {
        background-color: $accent;
        position: fixed;
        width: 100%;
        z-index: 100000;
        top: 0;
        height: 35px;
        display: flex;
        justify-content: center;
        a {
            margin: 0; } }
    &__btn {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: auto;
        background: transparent;
        border: none;
        &_mobile {
            margin: auto;
            height: 100%; } }
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 25px;
        color: #ffffff;
        background-color: darken($accent, 5%); }
    &__total {
        font-size: 14px;
        color: #ffffff;
        padding-left: 80px; } }

.slider {
    position: relative;
    &__item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &_first {
            background-image: url('../img/slider-image.png'); } }
    .slick-arrow {
        position: absolute;
        z-index: 100;
        top: 48%;
        border: none;
        background: transparent;
        width: 60px;
        height: 60px;
        color: #000000;
        font-size: 30px;
        &_white {
            background-color: #ffffff;
            opacity: 0.64; } }
    .slick-next {
        right: 0;
        i {
            position: relative;
            right: -5px; } }
    .slick-prev {
        left: 0;
        i {
            position: relative;
            left: -5px; } }
    .slick-dots {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        margin-top: 90px;
        li {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 15px;
            background-color: $accent;
            opacity: 0.3; }
        .slick-active {
            opacity: 1; }
        button {
            display: none; } } }

.offer {
    position: relative;
    text-align: center;
    padding: 250px 0;
    width: 650px;
    left: 50%;
    margin-left: -325px;
    &__heading {
        opacity: 0;
        margin: 0;
        padding: 0;
        color: #000000;
        text-transform: uppercase;
        font-size: 35px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 10px;
        animation: fadeInDown 1s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        span {
            color: $accent; } }
    &__subheading {
        opacity: 0;
        margin: 0;
        padding: 0;
        color: $grey-text;
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 30px;
        padding: 0 60px;
        animation: fadeInUp 1s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards; }
    &__btn {
        opacity: 0;
        animation: fadeInUp 2s;
        animation-delay: 3s;
        animation-fill-mode: forwards; } }

.adv-block {
    margin-top: 30px; }

.adv-card {
    text-align: center;
    color: #000000;
    &_first {
        height: 530px;
        padding: 40px 80px;
        background: url('../img/adv-card.png') no-repeat center / cover; }
    &_second {
        height: 250px;
        padding: 60px 30px;
        text-align: left;
        margin-bottom: 30px;
        background: url('../img/adv-card-2.png') no-repeat top; }
    &_third {
        height: 250px;
        padding: 60px 30px;
        text-align: left;
        background: url('../img/adv-card-2.png') no-repeat bottom; }
    &__subheading {
        display: block;
        font-size: 18px;
        letter-spacing: 3.78px;
        margin-bottom: 7px; }
    &__heading {
        margin: 0;
        padding: 0;
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 12px; }
    &__text {
        font-size: 15px;
        line-height: 24px;
        color: $grey-text; } }

.section {
    &__heading {
        margin: 0;
        padding: 0;
        text-align: center;
        overflow-x: hidden;
        font-size: 28px;
        letter-spacing: 0.56px;
        font-weight: 700;
        line-height: 2;
        &:after, &:before {
            content: '';
            display: inline-block;
            background-color: #c9c9c9;
            vertical-align: middle;
            width: 100%;
            height: 1px;
            position: relative; }
        &:before {
            margin-left: -100%;
            left: -50px; }
        &:after {
            margin-right: -100%;
            right: -50px; } }
    &__logo-heading {
        line-height: 5; } }

.popular {
    margin: 100px 0;
    &__btn {
        display: block;
        margin: auto;
        margin-top: 80px; } }

.switch-block {
    width: 260px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 60px;
    &__active {
        font-weight: 700; }
    span {
        font-size: 15px;
        cursor: pointer; } }

.product {
    position: relative;
    text-align: center;
    &_w225 {
        padding: 0 15px; }
    &__thumb {
        height: 350px;
        padding: 40px 0;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            line-height: 1.3; }
        img {
            margin: auto;
            width: auto;
            height: 100%; } }
    &__heading {
        margin: 0;
        padding: 0;
        margin: 8px 0;
        height: 47px;
        a {
            font-size: 18px;
            color: #000000;
            line-height: 1.3; } }
    p {
        padding: 0;
        margin: 0;
        font-size: 15px;
        line-height: 24px;
        color: $grey-text;
        margin-bottom: 15px; }
    &__price {
        font-weight: 700;
        font-size: 15px; }
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: transparent;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-color: #f0f0f0;
        transition: 0.3s;
        &:hover {
            background-color: #e0e0e0; }
        &_accent {
            background-color: $accent;
            &:hover {
                background-color: darken($accent, 5%); } } }
    &__icon {
        font-size: 12px;
        color: #272727;
        &_accent {
            color: #ffffff; } } }

.product-eye-btn, .product-cart-btn, .product-heart-btn {
    transition: all .5s ease; }

.product:hover > .product-eye-btn {
    right: 85px;
    opacity: 1; }

.product:hover > .product-heart-btn {
    right: 140px;
    opacity: 1; }

.product:hover > .product-cart-btn {
    right: 30px;
    opacity: 1; }

.discount {
    padding: 130px 0;
    background: url('../img/discount-bg.png') no-repeat center / cover;
    text-align: center;
    &__heading {
        font-size: 30px;
        line-height: 50px;
        color: #000000;
        margin-bottom: 35px;
        span {
            color: $accent;
            font-weight: 700; } } }

.news-block {
    margin-top: 30px;
    margin-bottom: 90px; }

.news-card {
    padding: 60px 100px 60px 30px;
    &_first {
        background: url('../img/news-block-1.png') no-repeat center / cover; }
    &_second {
        background: url('../img/news-block-2.png') no-repeat center / cover; }
    &__heading {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 700;
        color: #000000;
        margin-bottom: 10px; }
    &__text {
        margin: 0;
        padding: 0;
        font-size: 15px;
        line-height: 24px;
        color: $grey-text;
        margin-bottom: 15px; }
    &__link {
        color: $accent;
        font-weight: 700;
        font-size: 15px;
        transition: 0.3s;
        &:visited {
            color: $accent; }
        &:hover {
            color: darken($accent, 4%); } } }

.about {
    &__text {
        margin: 30px 0;
        p {
            font-size: 15px;
            color: $grey-text;
            line-height: 28px;
            text-align: center; } } }

.about-social-links {
    display: flex;
    justify-content: space-between;
    width: 220px;
    margin: auto;
    &__link {
        width: 45px;
        height: 45px;
        background-color: #f0f0f0;
        color: #000000;
        border-radius: 50%;
        text-align: center;
        color: #272727;
        line-height: 3;
        font-size: 15px;
        transition: 0.5s;
        &:hover {
            background-color: #e4e4e4; } } }

.footer {
    margin-top: 100px;
    padding: 80px 0;
    background-image: linear-gradient(180deg, #22353e 0%, #17252c 100%);
    &__heading {
        font-size: 15px;
        line-height: 26px;
        color: #ffffff; } }

.footer-menu {
    display: flex;
    justify-content: space-between;
    &__heading {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #e2e2e2;
        font-weight: 700;
        margin-bottom: 25px; }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none; }
    &__link {
        font-size: 15px;
        color: #c2c2c2;
        line-height: 2;
        transition: 0.5s;
        &:hover {
            color: #ffffff; } } }

.footer-info {
    text-align: right;
    &__link {
        margin-right: 15px;
        color: #c2c2c2;
        transition: 0.5s;
        margin-bottom: 20px;
        font-size: 15px;
        display: inline-block;
        &:hover {
            color: #ffffff; } }
    &__icon {
        color: #ffffff;
        font-size: 20px; } }

.footer-social-links {
    margin-top: 15px;
    &__link {
        font-size: 18px;
        color: #d1d1d1;
        transition: 0.5s;
        margin-right: 15px;
        &:last-child {
            margin-right: 0; }
        &:hover {
            color: #ffffff; } } }

.rights {
    display: block;
    padding: 15px 0;
    background-color: #162329;
    &__text {
        display: block;
        font-size: 15px;
        color: #858585;
        text-align: center; } }

.mobile-menu {
    display: none;
    position: fixed;
    overflow: hidden;
    background-color: rgba(0,0,0,0.9);
    height: 100vh;
    width: 100%;
    z-index: 10000000000000;
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 40px; }
    &__user-list {
        border-top: 1px solid #828282;
        padding-top: 40px; }
    &__link {
        display: block;
        color: #ffffff;
        font-size: 15px;
        padding: 10px 0;
        &:hover {
            color: #ffffff; }
        &:visited {
            color: #ffffff; } }
    &__user-link {
        i {
            margin-right: 15px; } }
    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        i {
            color: #ffffff;
            font-size: 20px; } } }

.breadcrumb {
    padding: 0;
    margin: 0;
    padding: 40px 0;
    margin-bottom: 40px;
    background-color: $grey;
    text-align: center;
    & li:first-child a {
        color: $accent; }
    & li a {
        color: #121212;
        font-size: 13px !important;
        display: inline-block !important; } }

.discount-banner {
    text-align: center;
    padding: 20px 30px;
    margin-bottom: 30px;
    background: url('../img/discont-banner.png') no-repeat center / cover;
    &__heading {
        font-size: 25px;
        font-weight: 700;
        color: #000000;
        span {
            color: $accent; } }
    &__subheading {
        margin: 0;
        padding: 0;
        display: block;
        font-size: 15px;
        margin-bottom: 120px;
        color: #000000; } }

.content__heading {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: #000000;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.2; }

.oil-banner {
    background: url('../img/oil-banner.png') no-repeat center / cover;
    padding: 35px;
    text-align: right;
    &__heading {
        margin: 0;
        padding: 0;
        font-size: 30px;
        font-weight: 700;
        color: #000000;
        span {
            color: $accent; } }
    &__subheading {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #000000; } }

.category-description {
    margin: 20px 0 50px 0;
    p {
        margin: 0;
        padding: 0;
        color: #2f2f2f;
        font-size: 15px;
        line-height: 26px; } }

.product-gird {
    &__btn {
        border: none;
        background: transparent;
        font-size: 20px;
        margin-right: 10px; } }

.category-options {
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 50px;
    & .input-group-addon {
        border: none;
        box-shadow: none;
        font-size: 13px; }
    & .form-control {
        border: none;
        box-shadow: none;
        font-size: 13px; } }

.content {
    & .product {
        margin-bottom: 30px; } }

.product-s {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &__thumb {
        height: 65px;
        img {
            height: 100%;
            max-width: auto; } }
    &__body {
        margin-left: 30px; }
    &__heading {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        color: #7a7a7a;
        font-size: 13px; }
    &__price {
        font-size: 13px;
        color: #000000;
        font-weight: 700; } }

.pag-nav {
    margin-top: 50px; }

.thumbnail {
    border: none;
    box-shadow: none;
    height: 430px;
    img {
        height: 100% !important; } }

.product-options {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.7;
    font-size: 15px;
    margin-bottom: 30px; }

.price {
    font-size: 24px;
    color: #000000;
    margin-bottom: 40px; }

.amount {
    display: flex;
    position: relative;
    margin-bottom: 40px;
    &__label {
        position: absolute;
        top: -25px;
        left: 0;
        font-size: 13px;
        color: $grey-text;
        font-weight: 400; }
    &__ctrl {
        cursor: pointer;
        width: 45px;
        height: 45px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 20px;
        color: #242424;
        text-align: center;
        line-height: 2.2;
        z-index: 1;
        transition: 0.5s;
        &:hover {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16); } }
    &__input {
        width: 45px;
        height: 45px;
        background-color: $accent;
        color: #ffffff;
        font-size: 15px;
        border: none;
        text-align: center; } }

.product-card-btn {
    margin-bottom: 15px; }

.description {
    margin-top: 50px;
    & .nav-tabs li {
        padding: 12px 0;
        font-weight: 700;
        font-size: 18px; }

    & .tab-content {
        padding-top: 15px; } }


// Styles for OcStore 2.3
.alert-top {
    position: fixed;
    z-index: 100000000;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    color: #000;
    border-radius: 0;
    padding: 10px;
    background: #d9edd1; }

.alert-top a {
    transition: 0.3s; }

.product-gird {
    & .active {
        color: $accent; } }

.product-list {
    & .product {
        display: flex;
        align-items: center;
        text-align: left;
        margin-bottom: 0; }
    .product__thumb {
        margin-right: 40px; } }

.popular-column__heading {
    font-size: 25px;
    margin-bottom: 20px; }

.information {
    p {
        font-size: 16px;
        line-height: 25px; } }

.alert-danger {
    background-image: none; }

.btn {
    transition: 0.3s;
    border-radius: 0; }

.btn-primary {
    background-color: $accent;
    border: 1px solid $accent;
    &:focus {
        background-color: darken($accent, 4%) !important;
        border: 1px solid $accent !important; }
    &:hover {
        box-shadow: none;
        background-color: darken($accent, 4%); } }

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    background-color: $accent !important; }

#boc_order {
    .input-group-addon {
        border: none;
        background: none;
        i {
            font-size: 16px;
            color: $grey-text; } }
    .has-warning .form-control {
        border-color: #dcdcdc;
        border-radius: 0; }
    .has-warning .form-control:focus {
        box-shadow: none; }
    .has-error .form-control {
        border-color: red; } }

.account-wishlist {
    & .price {
        font-size: inherit; } }

.category-banner {
    margin-bottom: 40px;
    p {
        font-size: 15px;
        line-height: 24px; } }

.category-thumb {
    margin-bottom: 15px;
    img {
        width: 100%; } }

.pag-nav .row {
    display: flex;
    align-items: center; }

.pagination .active span {
    background-color: $accent;
    border-color: $accent; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
    background-color: #dcf3d3;
    border-color: #dcf3d3; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: $accent;
    border-color: $accent; }

.discount-form {
    &__input {
        display: block;
        width: 100%;
        margin-top: 10px;
        padding-left: 10px; }
    &__label {
        margin-left: 10px; }
    &__check {
        display: flex;
        align-items: flex-start;
        margin-top: 15px;
        position: relative; }
    & #politic-error {
        position: absolute;
        bottom: -20px; }
    & .error {
        font-size: 13px;
        font-weight: 300;
        color: red; } }

.hidden-input {
    display: none; }

#loader {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 46, 56, 0.9);
    overflow: hidden;
    z-index: 100000; }

#loader img {
	position: absolute;
	width: 50px;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	top: 50%;
	margin-top: -25px; }

#overlay {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(43, 46, 56, 0.9);
    left: 0px;
    top: 0px;
    cursor: pointer;
    z-index: 10000; }

#thx {
	background: #fff;
	position: absolute;
	left: 50%;
	margin-left: -170px;
	top: 50%;
	padding: 30px;
	margin-top: -20px; }

.blog_title a {
    font-size: 20px !important;
    color: #000000 !important; }

@import "parts/media.sass";
