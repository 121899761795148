@media (max-width: 1200px) {
	.menu {
		&__link {
			padding: 12px 20px; } }

	.offer {
		padding: 180px 0; }

	.top-sidebar {
		&__text {
			font-size: 12px; } }

	.adv-card {
		&_first {
			padding: 40px 60px; }
		&_second {
			background-position: left; }
		&_third {
			background-position: left; } }

	.product {
		margin-bottom: 30px;
		&__thumb {
			height: 255px;
			padding: 30px 0; } }

	.popular {
		margin: 80px 0;
		&__btn {
			margin-top: 50px; } }

	.discount {
		background: url('../img/discount-bg-mobile-768.png') no-repeat center / cover;
		padding: 100px 0; }

	.news-card {
		padding: 50px 60px 50px 30px; }

	.footer {
		&__heading {
			font-size: 13px; } } }

@media (max-width: 991px) {
	.top-sidebar .row {
		flex-direction: column;
		flex-direction: column-reverse; }

	.top-sidebar {
		padding: 30px 0;
		&__info-block {
			justify-content: center;
			margin-bottom: 25px; }
		&__text {
			font-size: 12px; }
		&__link {
			font-size: 18px; } }

	.search {
		width: 60%;
		margin: auto; }

	.social-links {
		&__link {
			font-size: 18px; } }

	.top-nav {
		padding-top: 50px; }

	.slider {
		&__item {
			&_first {} } }

	.offer {
		padding: 60px 0; }

	.adv-card {
		&_first {
			margin-bottom: 30px; }
		&_second {
			background-position: top; }
		&_third {
			background-position: bottom; } }

	.switch-block {
		margin-bottom: 40px; }

	.news-card {
		&_first {
			margin-bottom: 30px; } }

	.footer {
		padding: 60px 0; }

	.footer-info {
		text-align: center;
		margin-top: 50px; }

	.footer-menu {
		padding-top: 15px; }

	.news-block {
		margin-bottom: 60px; }

	.product__btn {
		opacity: 1;
		border-radius: 0; }

	.product-eye-btn {
		right: 45px; }

	.product-heart-btn {
		right: 90px; }

	.product:hover > .product-eye-btn {
		right: 45px; }

	.product:hover > .product-heart-btn {
		right: 90px; }

	.product:hover > .product-cart-btn {
		right: 0px; }

	.product__thumb {
		padding: 50px 0 10px 0; }

	.thumbnail {
		height: 350px; }

	.product-options {
		text-align: center; }

	.price {
		text-align: center; }

	.amount {
		display: flex;
		justify-content: center;
		&__label {
			left: 50%;
			margin-left: -21px; } }

	.product-card-btn {
		margin: auto;
		margin-bottom: 10px; }

	.product-card-d-btn {
		margin: auto; }

	.product-card-heading {
		text-align: center; }

	.alert-success {
		top: 35px; } }

@media (max-width: 767px) {
	.search {
		width: 100%; }

	.offer {
		&__heading {
			font-size: 24px;
			line-height: 38px; }
		&__subheading {
			padding: 0 20px; } }

	.discount {
		&__heading {
			font-size: 24px;
			line-height: 38px; } }

	.footer {
		&__heading {
			margin-bottom: 30px; } }

	.footer-menu {
		display: block;
		&__block {
			margin-bottom: 30px; } }

	.footer-info {
		text-align: left; }

	.pag-nav .row {
		display: inherit; } }

@media (max-width: 575px) {

	.slider {
		&__item {
			&_first {
				background-image: url('../img/slider-image-mobile.png'); } } }

	.offer {
		width: 100%;
		margin: 0;
		left: 0;
		&__subheading {
			line-height: 28px; } }

	.adv-block {
		margin-top: 15px; }

	.adv-card {
		&__heading {
			font-size: 20px; }
		&__subheading {
			font-size: 16px; }
		&_first {
			padding: 20px;
			margin-bottom: 15px; }
		&_second {
			text-align: center;
			background: #e3e0e6;
			margin-bottom: 15px;
			padding: 30px;
			height: auto; }
		&_third {
			background: #e3e0e6;
			height: auto;
			text-align: center;
			padding: 30px; } }

	.popular {
		margin: 30px 0;
		&__btn {
			margin-top: 30px; } }

	.discount {
		padding: 60px 0; }

	.news-card {
		padding: 30px;
		&_first {
			margin-bottom: 15px; } }

	.news-block {
		margin-top: 15px;
		margin-bottom: 30px; }

	.footer {
		margin-top: 60px; }

	.breadcrumb {
		padding: 30px 0; }

	.oil-banner {
		&__heading {
			font-size: 22px; }
		&__subheading {
			font-size: 12px; } }

	.content {
		&__heading {
			text-align: center; } }

	.thumbnail {
		height: 250px; }

	.pag-nav {
		margin-top: 30px; }

	.modal, .modal-backdrop.modal {
        top: 35px; }

	.checkout-cart {
		& .buttons {
			a {
				font-size: 11px; } } }

	.discount-form #politic-error {
		bottom: -40px; }

	.discount-form button {
		margin-top: 25px; } }
